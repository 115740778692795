import { Params } from '@angular/router';
import { Action, createReducer, on } from '@ngrx/store';
import { BookingAggregate } from 'src/app/shared/models/booking.model';
import { DriversActions } from '.';
import { AccountUser } from '../../../../../../src/app/shared/models/account-user.model';
import { DriverAggregate, DriverEta, DriverState, DriverTelemetry } from '../../shared/models/driver.model';
import { DriverSearchResponse } from '../../shared/models/driver.vm.model';
import { SearchResponse } from '../../shared/models/elastic-api.model';
import { VehicleAggregate } from '../../shared/models/vehicle.model';

export interface SupportAgentDriversState {
  isLoading: boolean;
  queryParams: Params;
  userPageSize: number;
  searchResponse: SearchResponse<DriverSearchResponse>;
  drivers: DriverAggregate[];
  driverUsers: AccountUser[];
  driverUser: AccountUser;
  driverDetails: DriverAggregate;
  driverTelemetry: DriverTelemetry;
  driverState: DriverState;
  trips: BookingAggregate[];
  driverEta: DriverEta;
  vehicle: VehicleAggregate;
}

export const initialState: SupportAgentDriversState = {
  isLoading: false,
  queryParams: null,
  userPageSize: null,
  searchResponse: null,
  drivers: [],
  driverUsers: [],
  driverUser: null,
  driverDetails: null,
  driverTelemetry: null,
  driverState: null,
  trips: [],
  driverEta: null,
  vehicle: null,
};

export const driverReducer = createReducer(
  initialState,
  on(DriversActions.queryParamsChanged, (state, action) => ({
    ...state,
    queryParams: action.queryParams,
    isLoading: true,
    drivers: [],
  })),
  on(DriversActions.pageChanged, (state, action) => ({
    ...state,
    userPageSize: action.page.pageSize,
  })),
  on(DriversActions.searchResponseFetched, (state, action) => ({
    ...state,
    searchResponse: action.searchResponse,
  })),
  on(DriversActions.driverListFetched, (state, action) => ({
    ...state,
    drivers: action.drivers,
    isLoading: false,
  })),
  on(DriversActions.driverDetailsFetched, (state, action) => ({
    ...state,
    driverDetails: action.driverAggregate,
  })),
  on(DriversActions.driverTelemetryFetched, (state, action) => ({
    ...state,
    driverTelemetry: action.telemetry,
  })),
  on(DriversActions.driverStateFetched, (state, action) => ({
    ...state,
    driverState: action.state,
  })),
  on(DriversActions.tripsFetched, (state, action) => ({
    ...state,
    trips: action.trips,
  })),
  on(DriversActions.driverEtaFetched, (state, action) => ({
    ...state,
    driverEta: action.eta,
  })),
  on(DriversActions.vehicleFetched, (state, action) => ({
    ...state,
    vehicle: action.vehicle,
  })),
  on(DriversActions.driverUsersFetched, (state, action) => ({
    ...state,
    driverUsers: action.driverUsers,
  })),
  on(DriversActions.driverUserFetched, (state, action) => ({
    ...state,
    driverUser: action.driverUser,
  })),
  on(DriversActions.driverDetailsDestroyed, (state, action) => ({
    ...state,
    driverDetails: null,
    driverTelemetry: null,
    driverState: null,
    trips: [],
    driverEta: null,
    vehicle: null,
  })),
);

export function reducer(state: SupportAgentDriversState, action: Action) {
  return driverReducer(state, action);
}
