// noinspection DuplicatedCode

import { createSelector } from '@ngrx/store';
import { mapPinMarkerWithCustomLabelToFeature, mapVehicleToFeature } from 'src/app/ol-map/mappings/ol-map-mappings';
import { LiveMapLayer, LiveMapLayerType, OlMapFeature } from 'src/app/ol-map/models/live-map.vm.model';
import { getBookingStatus, getBookingStatusColor } from 'src/app/shared/mappings/mappings';
import { BookingAggregate } from 'src/app/shared/models/booking.model';
import { AccountUser } from '../../../../../../src/app/shared/models/account-user.model';
import { AuthSelectors } from '../../auth/store';
import {
  formatDriverEta,
  getDriverStateVM,
  getDriverStatusType,
  getDriverTitle,
  getLocationAddress,
  getPriceType,
  getStopType,
  mapProductFeatures,
  mapQueryParamsToPage,
  mapQueryParamsToSearchForm,
} from '../../shared/mappings/mappings';
import { Area } from '../../shared/models/area.model';
import {
  DriverAggregate,
  DriverEta,
  DriverState,
  DriverStateJob,
  DriverStateStop,
  DriverTelemetry,
} from '../../shared/models/driver.model';
import {
  DriverDetailsVM,
  DriverListVM,
  DriverSearchResponse,
  DriverStateJobVM,
  DriverStateStopVM,
  DriverTripListVM,
} from '../../shared/models/driver.vm.model';
import { SearchResponse } from '../../shared/models/elastic-api.model';
import { ProductFeature } from '../../shared/models/product-feature.model';
import { VehicleAggregate } from '../../shared/models/vehicle.model';
import { FirestoreSelector } from '../../store/firestore';
import { SupportAgentState } from '../../store/support-agent.state';

export const driversState = (state: SupportAgentState) => state.driversState;

export const isUserSignedIn = createSelector(AuthSelectors.signedInSelector, isSignedIn => isSignedIn);

export const userAreaIdsSelector = createSelector(AuthSelectors.userAreaIdsSelector, areaIds => areaIds);

export const storagePageSizeSelector = createSelector(
  AuthSelectors.browserStorageSelector,
  storage => storage?.find(item => item.key === 'pageSize:supportAgent:drivers')?.value,
);

export const dispatchTagsSelector = createSelector(FirestoreSelector.dispatchTagsSelector, tags => tags);

export const areasSelector = createSelector(FirestoreSelector.areaListSelector, areas => areas);

export const productFeatureListSelector = createSelector(
  FirestoreSelector.productFeatureListSelector,
  features => features,
);

export const isLoadingSelector = createSelector(driversState, state => state.isLoading);

export const driverAggregateSelector = createSelector(driversState, state => state.drivers);

export const driverUsersSelector = createSelector(driversState, state => state.driverUsers);

export const driverDetailsSelector = createSelector(driversState, state =>
  state.driverDetails ? state.driverDetails : null,
);

export const driverUserSelector = createSelector(driversState, state => state.driverUser);

export const driverStateSelector = createSelector(driversState, state => state.driverState);

export const driverTelemetrySelector = createSelector(driversState, state => state.driverTelemetry);

export const searchResponseSelector = createSelector(driversState, state => state.searchResponse);

export const queryParamsSelector = createSelector(driversState, state => state.queryParams);

export const userPageSizeSelector = createSelector(driversState, state => state.userPageSize);

export const driverEtaSelector = createSelector(driversState, state => state.driverEta);

export const tripsSelector = createSelector(driversState, state => state.trips);

export const vehicleSelector = createSelector(driversState, state => state.vehicle);

export const driverIdSelector = createSelector(driverDetailsSelector, details => details?.doc_id);

export const vehicleIdSelector = createSelector(driverStateSelector, state => (state ? state.vehicle_id : null));

export const driverListSelector = createSelector(driverAggregateSelector, driverUsersSelector, (drivers, users) =>
  drivers?.length > 0 && users?.length > 0 ? mapDriverAggrToDriverList(drivers, users) : [],
);

function mapDriverAggrToDriverList(drivers: DriverAggregate[], users: AccountUser[]): DriverListVM[] {
  return drivers.map(d => {
    const user = users.filter(u => u?.id).find(u => u.id === d.doc_id);
    return {
      id: d.doc_id,
      email: user?.email,
      title: d.driver?.first_name + ' ' + d.driver?.last_name,
      phone: d.driver?.phone,
      image: d.photo && d.photo.url !== null && d.photo.url.length > 0 ? d.photo.url + '=s500-c' : null,
      taxiLicenseNumber: d.driver?.taxi_license_number,
      disabled: user?.disabled,
      rating: d.rating ? d.rating.value : 0,
      skipLocationValidation: d.driver?.skip_location_validation ? d.driver.skip_location_validation : false,
    };
  });
}

export const driverDetailsVMSelector = createSelector(
  driverDetailsSelector,
  driverUserSelector,
  driverStateSelector,
  vehicleSelector,
  productFeatureListSelector,
  (details, user, state, vehicle, features) =>
    details ? mapDriverAggrToDriverDetails(details, user, state, vehicle, features) : null,
);

function mapDriverAggrToDriverDetails(
  aggregate: DriverAggregate,
  user: AccountUser,
  state: DriverState,
  vehicle: VehicleAggregate,
  features: ProductFeature[],
): DriverDetailsVM {
  return {
    id: aggregate.doc_id,
    title: aggregate.driver.first_name + ' ' + aggregate.driver.last_name,
    image:
      aggregate.photo && aggregate.photo.url !== null && aggregate.photo.url.length > 0
        ? aggregate.photo.url + '=s500-c'
        : 'assets/images/person.svg',
    firstName: aggregate.driver.first_name,
    lastName: aggregate.driver.last_name,
    taxiLicenseNumber: aggregate.driver.taxi_license_number,
    phone: aggregate.driver.phone,
    reference: aggregate.driver.reference,
    defaultAreaId: aggregate.driver.default_area_id,
    dispatchTagIds: aggregate.driver.dispatch_tag_ids,
    rating: aggregate.rating ? aggregate.rating.value : 0,
    skipLocationValidation: aggregate.driver?.skip_location_validation
      ? aggregate.driver.skip_location_validation
      : false,
    status: getDriverStatusType(state?.status?.type),
    job: mapDriverStateJobToDriverStateJobVM(state?.job),
    vehicle: getVehicleTitle(vehicle),
    vehicleFeatures: mapProductFeatures(vehicle?.vehicle?.feature_ids, features),
    email: user?.email,
    disabled: user?.disabled,
  };
}

function mapDriverStateJobToDriverStateJobVM(job: DriverStateJob): DriverStateJobVM {
  return job
    ? {
        bookingId: job.booking_id,
        acceptedAt: job.accepted_at,
        inProgressAt: job.in_progress_at,
        cancelledAt: job.cancelled_at,
        completedAt: job.completed_at,
      }
    : null;
}

export const driverStateStopsVMSelector = createSelector(driverStateSelector, state =>
  state ? mapDriverStateStopsDriverStateStopsVM(state.stops) : [],
);

function mapDriverStateStopsDriverStateStopsVM(stops: DriverStateStop[]): DriverStateStopVM[] {
  return stops?.map(stop => ({
    location: stop?.location,
    type: getStopType(stop?.type),
  }));
}

export const driverActiveTripIdSelector = createSelector(driverStateSelector, state =>
  state ? state.job?.booking_id : null,
);

export const driverTripListSelector = createSelector(tripsSelector, areasSelector, (bookings, areas) =>
  bookings ? mapBookingsToTripList(bookings, areas) : null,
);

function mapBookingsToTripList(bookings: BookingAggregate[], areas: Area[]): DriverTripListVM[] {
  return bookings.map(aggregate => mapBooking(aggregate, areas));
}

function mapBooking(aggregate: BookingAggregate, areas: Area[]): DriverTripListVM {
  const booking = aggregate.booking;
  const trip = aggregate.trip;
  const vehicleLicensePlate = trip?.vehicle?.license_plate;
  const tripPrice = aggregate.trip_price;

  const pickup =
    trip && trip.pickup
      ? getLocationAddress(trip.pickup)
      : booking && booking.pickup
        ? getLocationAddress(booking.pickup)
        : '';

  const dropoff =
    trip && trip.dropoff
      ? getLocationAddress(trip.dropoff)
      : booking && booking.dropoff
        ? getLocationAddress(booking.dropoff)
        : '';

  const driverFullName = trip?.driver != null ? trip.driver.first_name + ' ' + trip.driver.last_name : '';

  return {
    bookingId: aggregate.doc_id,
    createdAt: booking.created_at,
    pickupAt: booking.pickup_at,
    pickup,
    dropoff,
    driverFullName,
    vehicleLicensePlate,
    totalPrice: tripPrice?.customer_net_amount?.display,
    priceType: tripPrice ? getPriceType(tripPrice.type) : '',
    timeZoneId: mapBookingAggrToAreaTimeZone(areas, booking.area_id),
    rating: aggregate.driver_rating?.rating,
    status: getBookingStatus(aggregate),
    statusColorClass: getBookingStatusColor(getBookingStatus(aggregate)),
  };
}

function mapBookingAggrToAreaTimeZone(areas: Area[], areaId: string) {
  return areas.find(area => area.id === areaId)?.time_zone_id;
}

export const mapSelector = createSelector(
  driverDetailsSelector,
  driverStateSelector,
  driverTelemetrySelector,
  driverEtaSelector,
  (driver, state, telemetry, eta) => (driver ? mapDriverToMap(driver, state, telemetry, eta) : null),
);

function mapDriverToMap(
  aggregate: DriverAggregate,
  state: DriverState,
  telemetry: DriverTelemetry,
  driverEta: DriverEta,
): LiveMapLayer[] {
  const features: OlMapFeature[] = [];

  if (state?.stops?.length > 0) {
    state.stops.forEach((stop, index) => {
      features.push(
        mapPinMarkerWithCustomLabelToFeature({
          id: getLocationAddress(stop.location),
          coordinate: {
            lat: stop.location.lat,
            lng: stop.location.lng,
          },
          label: (index + 1).toString(),
        }),
      );
    });
  }

  if (telemetry) {
    const driverState = getDriverStateVM(state.driver_id, state.status?.type, state.job, state.vehicle_id);
    features.push(
      mapVehicleToFeature({
        id: state.driver_id,
        name: getDriverTitle(aggregate),
        coordinate: {
          lat: telemetry.latitude,
          lng: telemetry.longitude,
        },
        status: driverState ? driverState.status : null,
        rotation: (telemetry.direction * Math.PI) / 180,
        eta: driverEta ? formatDriverEta(driverEta.eta) : null,
      }),
    );
  }

  return [
    {
      type: LiveMapLayerType.DEFAULT,
      selectable: false,
      title: 'driver',
      features,
    },
  ];
}

export const searchResponseIdsSelector = createSelector(searchResponseSelector, response =>
  response ? mapSearchResponseHitsToList(response) : [],
);

function mapSearchResponseHitsToList(response: SearchResponse<DriverSearchResponse>): string[] {
  // eslint-disable-next-line no-underscore-dangle
  return response.hits?.hits?.map(hit => hit._id);
}

export const totalRecordsSelector = createSelector(searchResponseSelector, response =>
  response?.hits ? response.hits.total.value : 0,
);

export const listPageSize = createSelector(storagePageSizeSelector, userPageSizeSelector, (storage, state) =>
  !state ? (!storage ? 10 : storage) : state,
);

export const pageSelector = createSelector(queryParamsSelector, listPageSize, (params, pageSize) =>
  mapQueryParamsToPage(params, pageSize),
);

export const pageSizeSelector = createSelector(pageSelector, page => page.pageSize);

export const pageIndexSelector = createSelector(pageSelector, page => page.pageIndex);

export const searchFormSelector = createSelector(queryParamsSelector, userAreaIdsSelector, (params, areaIds) =>
  mapQueryParamsToSearchForm(params, areaIds),
);

function getVehicleTitle(vehicleAggregate?: VehicleAggregate) {
  return vehicleAggregate
    ? [
        vehicleAggregate.vehicle.license_plate,
        vehicleAggregate.vehicle.make,
        vehicleAggregate.vehicle.model,
        vehicleAggregate.vehicle.from_year,
      ]
        .filter(Boolean)
        .join(' ')
    : null;
}
